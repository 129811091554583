.artwork-container-outer {
    text-align: center;
    padding-bottom: 15px;
}

.artwork-container {
    display: inline-block;
    position: relative;
    text-align: left;
}

.artwork-container img {
    display: block;
}

.artwork-caption {
    color: #fff;
    text-decoration: none;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    padding: 10px;
}

@media (min-width: 992px) {
    .artwork-column {
        width: 30%;
        float: left;
    }
}

.artwork-column {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
/**
 * Site header
 */

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1140px;
  }
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}


.site-header {
  /* border-top: 5px solid $grey-color-dark;
  border-bottom: 1px solid $grey-color-light;
  min-height: $spacing-unit * 1.865; */

  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
  font-weight: 300;
  line-height: $base-line-height * $base-font-size * 2.25;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;

  &,
  &:visited {
    color: $grey-color-dark;
  }
}

.site-nav {
  float: right;
  line-height: $base-line-height * $base-font-size * 2.25;

  .nav-trigger {
    display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}



/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
  background-color: #292929;
  padding-bottom: 50px;
}

.footer-content {
  color: rgba(255, 255, 255, 0.65);
  font-size: 1.5em;
  text-align: center;
  width: 100%;
}

/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1;
}

.post-list {
  margin-left: 0;
  list-style: none;

  >li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
}
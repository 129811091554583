@charset "utf-8";

// Define defaults for each variable.

// $base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-family: "Gill Sans", "Gill Sans MT", Calibri, sans-serif !default;
$base-font-size:   14px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$text-color:       #333 !default;
$background-color: #f0eeee !default;
$brand-color:      #2a7ae2 !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Import partials.
@import
  "main_style/base",
  "main_style/layout",
  "main_style/carousel",
  "main_style/menu",
  "main_style/artwork"
;

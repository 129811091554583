/**
 * Carousel
 */

@keyframes tonext {
  75% {
    left: 0;
  }

  95% {
    left: 100%;
  }

  98% {
    left: 100%;
  }

  99% {
    left: 0;
  }
}

@keyframes tostart {
  75% {
    left: 0;
  }

  95% {
    left: -300%;
  }

  98% {
    left: -300%;
  }

  99% {
    left: 0;
  }
}

@keyframes snap {
  96% {
    scroll-snap-align: center;
  }

  97% {
    scroll-snap-align: none;
  }

  99% {
    scroll-snap-align: none;
  }

  100% {
    scroll-snap-align: center;
  }
}

ol,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.carousel {
  position: relative;
  /* padding-top: 75%; */
  filter: drop-shadow(0 0 10px #0003);
  perspective: 100px;
  max-height: 350px;
  height: 350px;
}

.carousel__viewport {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
}

.carousel__viewport::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.carousel__slide {
  position: relative;
  flex: 0 0 100%;
  width: 100%;
}

.carousel__slide:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -40%, 70px);
  color: #fff;
  font-size: 2em;
}

.carousel__snapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  scroll-snap-align: center;
}

@media (hover: hover) {
  .carousel__snapper {
    animation-name: tonext, snap;
    animation-timing-function: ease;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  .carousel__slide:last-child .carousel__snapper {
    animation-name: tostart, snap;
  }
}

@media (prefers-reduced-motion: reduce) {
  .carousel__snapper {
    animation-name: none;
  }
}

.carousel:hover .carousel__snapper,
.carousel:focus-within .carousel__snapper {
  animation-name: none;
}

.carousel__navigation {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
}

.carousel__navigation-list,
.carousel__navigation-item {
  display: inline-block;
}

.carousel__navigation-button {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #333;
  background-clip: content-box;
  border: 0.25rem solid transparent;
  border-radius: 50%;
  font-size: 0;
  transition: transform 0.1s;
}

.carousel::before,
.carousel::after,
.carousel__prev,
.carousel__next {
  position: absolute;
  top: 50%;
  width: 3rem;
  height: 3rem;
  transform: translateY(-50%);
  border-radius: 50%;
  font-size: 0;
  outline: 0;
  z-index: 50;
}

.carousel::before,
.carousel__prev {
  left: 1rem;
}

.carousel::after,
.carousel__next {
  right: 1rem;
}

.carousel::before,
.carousel::after {
  content: '';
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.9);
  background-size: 1.5rem 1.5rem;
  background-repeat: no-repeat;
  background-position: center center;
  color: #333;
  font-size: 2.5rem;
  line-height: 4rem;
  text-align: center;
  pointer-events: none;
}

.carousel::before {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,50 80,100 80,0' fill='%23333'/%3E%3C/svg%3E");
}

.carousel::after {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='100,50 20,100 20,0' fill='%23333'/%3E%3C/svg%3E");
}

/* Custom Style */

.carousel__item>img {
  display: block;
  max-width: 100%;

  width: auto;
  height: auto;
  max-height: 350px !important;
  margin-left: auto !important;
  margin-right: auto !important;

  border: 0;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.carousel__item {
  position: absolute !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  background: #333333 !important;
  background: rgba(0, 0, 0, 0.75) !important;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
  box-sizing: border-box;
  display: block;
  z-index: 10;
}

.carousel__caption {
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 15px;
}